.profile_name {
  padding: 5px;
}
.profile_name label {
  font-size: 16px;
  font-weight: 600;
}
.addMood-lb label {
  font-size: 16px;
  margin: 0 0 8px;
  display: inline-block;
  font-weight: 500;
}

.faq_delete {
  border: 1px solid #1c8efa;
  padding: 6px 11px;
  display: inline-block;
  border-radius: 3px;
  position: relative;
  top: -3px;
}
.adFer {
  background: #f00;
  border: none;
}

.input-tag {
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
}

.input-tag input {
  border: none;
  width: 100%;
  color: #000;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: #85a3bf;
  border-radius: 2px;
  color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

.border_perm {
  border: 1px solid #ccc;
  padding: 6px 0 6px;
  margin: 0;
  border-bottom: none;
}

.mainBorder_perm .border_perm:last-child {
  border-bottom: 1px solid #ccc;
}

.per_name {
  margin-left: 10px;
}
.mb_pert {
  margin-bottom: 0;
}
.rightMb_part {
  float: right;
}
